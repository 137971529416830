import * as React from "react";
import { useStyles } from "./InfrastructureManagement.styles";
import { Grid, Typography } from "@material-ui/core";
import HeadingDash from "../../../../common/components/HeadingDash/HeadingDash";
import IconCard from "../../../../common/components/IconCard/IconCard";
import { IconData } from "../../../../common/icons/IconData";
import { IconBattery } from "../../../../common/icons/IconBattery";
import { IconPowerfulAPI } from "../../../../common/icons/IconPowerfulAPI";
import { IconSystem } from "../../../../common/icons/IconSystem";

const EfficientSitesEnergyOptimization = (): JSX.Element => {
  const classes = useStyles();

  return (
    <section className={classes.futureProofSection}>
      <Typography variant="h2">
        <HeadingDash justify="flex-start" />
        Efficient sites energy optimization
      </Typography>
      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} lg={6}>
          <ul>
            <li>Save money through dynamic tariffs</li>
            <li>Avoid penalties for exceeding capacity</li>
            <li>Maximize on-site renewables for charging</li>
          </ul>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={2}>
            <Grid container item xs={6} md={3}>
              <IconCard icon={<IconBattery />} text="Adaptive EV Charging" />
            </Grid>
            <Grid container item xs={6} md={3}>
              <IconCard icon={<IconSystem />} text="Dynamic Load Control" />
            </Grid>
            <Grid container item xs={6} md={3}>
              <IconCard
                icon={<IconPowerfulAPI />}
                text="Grid constraint management"
              />
            </Grid>
            <Grid container item xs={6} md={3}>
              <IconCard icon={<IconData />} text="Arbitrage & trading model" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </section>
  );
};
export default EfficientSitesEnergyOptimization;
