import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  futureProofSection: {
    margin: 0,
    width: "100vw",
    border: "none",
    fontStyle: "normal",
    color: "#000",
    backgroundColor: "#F6F3F0",
    padding: "90px 10%",
  },
  "& p": {
    margin: "auto",
    width: "60vh",
    justifyContent: "center",
  },
}));
