import React from "react";
import EfficientSitesEnergyOptimization from "../Sections/EfficientSitesEnergyOptimization/EfficientSitesEnergyOptimization";
import StartingSection from "../../../common/sections/StartingSection/StartingSection";
import SummarySection from "../../../common/sections/SummarySection/SummarySection";
import ChooseServiceSection from "../../../common/sections/ChooseServiceSection/ChooseServiceSection";
import OurEcosystemSection from "../../../common/sections/OurEcosystemSection/OurEcosystemSection";
import { OptoEpIllustration } from "../../../common/images/illustrations/OptoEpIllustration";
import AllFeaturesSection from "../../../common/sections/AllFeaturesSection/AllFeaturesSection";
import { OptoEcosystem } from "../../../common/images/OptoEcosystem";
import { OptoAllFlatIcon } from "../../../common/images/OptoAllFlatIcon";
import { EmailInput } from "../../../common/Layout/Layout";
import { EpServicesEnum } from "../../../api/useEmailService";

const ChooseServiceSectionFeatures = [
  "Keep grid consumption to a minimum, using RES as a priority over grid power",
  "Determine when to use, store or sell the generated electricity",
  "Comprehensively factor in pricing signals, grid constraints, site parameters, and sustainability implications",
];

const OptoFeatures = [
  "Renewables & Generation Control",
  "EV & Lead Control",
  "Constraint Management",
  "Arbitrage & Trading",
  "Dynamic Tariffs",
  "CO2 emission reduction",
];

interface OptoEpProps {
  emailInputRef: EmailInput;
}

const OptoEnergyPlatform = ({ emailInputRef }: OptoEpProps): JSX.Element => {
  return (
    <>
      <StartingSection
        service={EpServicesEnum.Opto}
        emailInputRef={emailInputRef}
        title={EpServicesEnum.Opto}
        subtitle="Maximize renewable generation consumption for the greenest, most cost-efficient outcome"
        description="OptoEP delivers a powerful combination of EMS and BESS to provide EV charging and load control solutions to industrial and commercial sites, energy communities, and more."
        illustration={<OptoEpIllustration />}
      />
      <SummarySection>
        <>
          <b> OptoEP </b> delivers a powerful combination of EMS and BESS to
          <span> provide EV charging and load control solutions </span>
          to industrial
          <br /> and commercial sites, energy communities, and more.
        </>
      </SummarySection>
      <EfficientSitesEnergyOptimization />
      <ChooseServiceSection
        title="Why choose OptoEP Platform?"
        description="EP's intelligent platform delivers RES management for ultimate ROI. OptEP platform is designed to:"
        displayMLInfo={false}
        features={ChooseServiceSectionFeatures}
      />
      <OurEcosystemSection
        header="Our ecosystem"
        description="Intelligent Power Management platform"
        picture={<OptoEcosystem />}
      />

      <AllFeaturesSection
        heading="All features in one place"
        description=""
        icon={<OptoAllFlatIcon />}
        features={OptoFeatures}
      />
    </>
  );
};

export default OptoEnergyPlatform;
